<template>
  <Text
    class="newsletter-subscription-tagline mr-auto text-2xl font-light uppercase text-black mobile-only:mb-24 md:text-3xl"
    :class="{
      '!font-bold': isRebranding,
    }"
  >
    {{ $texts('footer.newsletter', 'Subscribe to our newsletter') }}
  </Text>
  <form :action="action" class="relative flex md:w-[286px]" method="get">
    <InputText
      v-model="email"
      type="email"
      title="newsletter-subscription"
      name="email"
      :placeholder="$texts('footer.email', 'Email')"
      required
      class="w-full"
    >
      <button
        type="submit"
        class="w-24 text-grey-dark-02"
        :title="$texts('formSubmitScreenReader', 'Submit')"
      >
        <SpriteSymbol name="arrow-right" class="h-full w-full" />
      </button>
    </InputText>
  </form>
</template>

<script lang="ts" setup>
const { $texts } = useEasyTexts()

const email = ref('')
const route = useRoute()

const isRebranding = await useFeatureFlag()

const action = computed(() => `/${route.params.prefix}/newsletter`)

watch(
  () => route.path,
  () => {
    email.value = ''
  },
)
</script>

<style scoped>
.newsletter-subscription-tagline {
  max-width: 90%;
}
</style>
