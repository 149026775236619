import { staticNodeKeys } from '#vuepal/static-node-keys'
import type {
  FooterConfigFragment,
  FooterMenuFragment,
  MainMenuFragment,
  StaticNodeFragment,
} from '#graphql-operations'
import { falsy, narrowTypeByProperty } from '@/helpers/type'

type InitData = {
  staticNodes: Record<string, string>
  currentCountryLabel: string
  footerMainMenu: FooterMenuFragment
  footerMetaMenu: FooterMenuFragment
  footerConfig: FooterConfigFragment
  menuLinks: MainMenuFragment['links']
  globalConfig: {
    termsOfUseUrl?: string
    privacyPolicyUrl?: string
  }
  langcodes: string[]
  topBannerPromotions?: Array<{
    fieldTopBanner?: string
  }>
}

type UseInitData = {
  data: globalThis.Ref<InitData>
}

/**
 * Given the static page keys build the mapping to be used to alter the routes.
 */
function buildStaticNodeMap(
  staticNodes: StaticNodeFragment[] = [],
): Record<string, string> {
  return (staticNodes || []).reduce<Record<string, string>>((acc, v) => {
    if (
      v.node?.id &&
      v.key &&
      'en' in v.node &&
      v.node.en &&
      'url' in v.node.en &&
      v.node.en.url?.path
    ) {
      acc[v.key] = v.node.en.url.path
    }

    return acc
  }, {})
}

export default async function (): Promise<UseInitData> {
  const isLoaded = useState('initDataLoaded', () => false)
  const language = useCurrentLanguage()
  const country = useCurrentCountry()
  const data = useState<InitData>('initData', () => {
    return {
      staticNodes: {},
      currentCountryLabel: '',
      footerMainMenu: {
        links: [],
      },
      footerMetaMenu: {
        links: [],
      },
      footerConfig: {},
      menuLinks: [],
      globalConfig: {},
      langcodes: [],
    }
  })

  const loadInitData = async () => {
    data.value = await useCachedGraphqlQuery('initData', {
      keys: staticNodeKeys,
    }).then((v) => {
      return {
        staticNodes: buildStaticNodeMap(v.data.staticNodes || []),
        currentCountryLabel: v.data.currentCountry?.label,
        footerMainMenu: narrowTypeByProperty(
          v.data.footerMainMenu,
          'links',
        ) || { links: [] },
        footerMetaMenu: narrowTypeByProperty(
          v.data.footerMetaMenu,
          'links',
        ) || { links: [] },
        footerConfig: v.data.footerConfig || {},
        menuLinks: narrowTypeByProperty(v.data.navbar, 'links')?.links || [],
        globalConfig: {
          privacyPolicyUrl: v.data.globalConfig?.privacyPolicy?.uri?.path,
          termsOfUseUrl: v.data.globalConfig?.termsOfUse?.uri?.path,
        },
        langcodes: (v.data.languages || []).map((v) => v.id).filter(falsy),
        topBannerPromotions: v.data.topBannerPromotions || [],
      }
    })
    isLoaded.value = true
  }

  if (!isLoaded.value) {
    await loadInitData()
  }

  if (import.meta.client) {
    watch(language, loadInitData)
    watch(country, loadInitData)
  }

  return { data }
}
