import plugin from 'tailwindcss/plugin'
import formKitTailwind from '@formkit/themes/tailwindcss'

/**
 * Keep track of our z indexes. The order of strings here decides the z-index order.
 */
const zIndex = [
  'navbar-overlay',
  'top-banner', // Add top-banner after navbar-overlay to ensure it's above the overlay
  'navbar',
  'admin-bar-overlay',
  'admin-bar',
  'page-modal',
  'storefinder-detail-mobile',
  'zendesk-chat-bot', // zendesk if the chat bot is open. This is especially relevant for mobile, where the chat bot is presented full screen.
  'vite-error-overlay',
].reduce<Record<string, number>>((acc, key, index) => {
  // 999998 is the z-index of the Zendesk Help Button.
  acc[key] = 999998 + 1 + index
  return acc
}, {})

export default {
  content: [
    './components/**/*.{vue,ts,js}',
    './pages/**/*.{vue,ts,js}',
    './layouts/**/*.{vue,ts,js}',
    './helpers/**/*.{vue,ts,js}',
    './stories/**/*.{vue,ts,js}',
    './formkit.config.ts',
  ],
  safelist: [],
  plugins: [
    formKitTailwind,
    /**
     * Various additional variants
     */
    formKitTailwind,
    plugin(function ({ addVariant }) {
      addVariant(
        'mobile-only',
        "@media screen and (max-width: theme('screens.md'))",
      )
      addVariant('not-last', '&:not(:last-child)')
      addVariant('not-first', '&:not(:first-child)')
    }),
  ],
  corePlugins: {
    textOpacity: false,
    container: false,
  },
  theme: {
    boxShadow: {
      'navbar-slide': '-2px 0px 0px 0px rgb(0 0 0 / 0.1)',
      none: 'none',
    },
    colors: {
      pink: '#FFA3A5',
      'pink-light': '#FFDCE6',
      red: '#DC0046',
      green: '#006E4B',
      'green-dark': '#025D40',
      black: '#000000',
      'blue-dark-01': '#295263',
      'grey-dark-01': '#343434',
      'grey-dark-02': '#565656',
      'grey-dark-03': '#808080',
      'grey-mid': '#B5B5B5',
      'grey-light-01': '#DDDDDD',
      'grey-light-02': '#EFEFEF',
      'grey-light-03': '#F6F5F5',
      white: '#FFFFFF',
      transparent: 'transparent',
      current: 'currentColor',
    },
    fontFamily: {
      sans: ['var(--font-family-sans)'],
    },
    screens: {
      md: '768px',
      lg: '1024px',
      xl: '1200px',
      xxl: '1440px',
      xxxl: '2496px',
    },
    spacing: {
      112: '112px',
      80: '80px',
      72: '72px',
      64: '64px',
      56: '56px',
      48: '48px',
      40: '40px',
      36: '36px',
      32: '32px',
      30: '30px',
      28: '28px',
      24: '24px',
      16: '16px',
      12: '12px',
      10: '10px',
      8: '8px',
      6: '6px',
      4: '4px',
      2: '2px',
      1: '1px',
      0: '0px',
    },
    fontSize: {
      '8xl': ['140px', '132px'], // 0.94
      '7xl': ['72px', '80px'], // 1.11
      '6xl': ['56px', '64px'], // 1.14
      '5xl': ['48px', '64px'], // 1.33
      '4xl': ['40px', '48px'], // 1.2
      '3xl': ['32px', '40px'], // 1.25
      '2xl': ['24px', '32px'], // 1.33
      xl: ['20px', '32px'], // 1.6
      lg: ['18px', '24px'], // 1.33
      md: ['18px', '32px'], // 1.78
      base: ['16px', '24px'], // 1.5
      sm: ['14px', '20px'], // 1.43
      xs: ['12px', '16px'], // 1.33
    },
    fontWeight: {
      bold: 700,
      medium: 500,
      normal: 400,
      light: 300,
    },

    extend: {
      zIndex: {
        ...zIndex,
      },

      transitionTimingFunction: {
        swing: 'cubic-bezier(0.56, 0.04, 0.25, 1)',
      },

      aspectRatio: {
        '16/9': '16 / 9',
      },
    },
  },
}
