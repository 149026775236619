<template>
  <div
    class="flex min-h-screen flex-col items-center justify-center p-16 text-center md:p-30 xl:p-64"
  >
    <NuxtLink :to="{ name: 'home' }">
      <SpriteSymbol
        :name="isRebranding ? 'logo-with-byline' : 'logo'"
        class="mx-auto mb-48 h-40 w-[100px]"
      />
    </NuxtLink>
    <div class="flex flex-1 items-center">
      <div>
        <p v-if="error.statusCode" class="typo-display">
          {{ error.statusCode }}
        </p>
        <h1 class="typo-headline-2 uppercase">
          {{ title }}
        </h1>

        <p class="typo-lead">
          {{ text }}
        </p>

        <NuxtLink :to="{ name: 'home' }" class="button is-primary mt-24">
          Homepage
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
type ErrorObject = {
  url?: string
  statusCode?: string
  statusMessage?: string
  message?: string
  stack?: string
}

const props = defineProps<{
  error: ErrorObject
}>()

const isRebranding = await useFeatureFlag()

const code = computed(() => props.error.statusCode?.toString())

const title = computed(() => {
  return props.error.message
})

const text = computed(() => {
  if (code.value === '404') {
    return 'The requested page could not be found.'
  }
  return 'Internal Server Error'
})
</script>
