<template>
  <DrupalLink
    v-if="to"
    :to="to"
    class="collection-teaser-root collection group relative block aspect-[4/5] bg-[length:100%_100%] bg-repeat-x uppercase focus-visible:outline-2 focus-visible:outline-offset-4 md:text-base"
    :class="{
      'bg-gradient-to-b from-grey-light-01 to-grey-dark-02':
        !style && !isRebranding,
      'text-white': !isRebranding,
      'bg-grey-light-02 text-black': isRebranding,
    }"
    :style="style"
  >
    <div
      class="collection-teaser-div relative z-20 pb-12 pt-[12.5%] transition duration-300 group-focus-visible:bg-grey-dark-01/10 md:pb-16"
      :class="{ 'group-hover:bg-grey-dark-01/10': !isRebranding }"
    >
      <div
        class="bg- mx-auto mb-[12.5%] h-[81cqw] w-[63cqw] p-[8%]"
        :class="{ 'bg-white': !isRebranding, 'bg-[#F6F6F5]': isRebranding }"
      >
        <MediaImage
          v-if="watchImage"
          v-bind="watchImage"
          :image-style="imageStyle"
          class="collection-image relative h-full w-full transition duration-300 ease-swing group-hover:scale-90"
          img-class="w-full h-full object-contain absolute top-0 left-0"
        />
      </div>
      <Text
        class="md:typo-lead shrink-0 leading-tight md:mb-2"
        :class="{
          'font-bold': isRebranding,
        }"
      >
        {{ label }}
      </Text>
      <span class="text-xs">{{ countLabel }}</span>
    </div>
  </DrupalLink>
</template>

<script lang="ts" setup>
import type { WatchCollectionTeaserFragment } from '#graphql-operations'

const isRebranding = await useFeatureFlag()

const { $texts } = useNuxtApp()

const props = defineProps<{
  id?: string
  label?: string
  url?: WatchCollectionTeaserFragment['url']
  // TODO: remove in ORIS-2834
  backgroundImage?: WatchCollectionTeaserFragment['backgroundImage']
  watchImage?: WatchCollectionTeaserFragment['watchImage']
  watchVariationsCount?: WatchCollectionTeaserFragment['watchVariationsCount']
}>()

// Cases where this element is used:
// a) Slider at the bottom of Collection pages https://oris.ch/en-US/product/watch/propilot-x
// b) Slider in the navigation
// c) Grid at top of https://oris.ch/en-US/product/watch
const imageStyle = defineImageStyle({
  type: 'pictures',
  pictures: {
    sm: {
      // width: 122, // a
      // width: 92, // b
      width: 230, // c
    },
    md: {
      width: 250, // a
      // width: 103, // b
      // width: 160, // c
    },
    lg: {
      width: 250, // a
      // width: 135, // b
      // width: 135, // c
    },
    xl: {
      width: 250, // a
      // width: 180, // b
      // width: 150, // c
    },
    xxl: {
      width: 250, // a
      // width: 200, // b
      // width: 175, // c
    },
    // not increasing for xxxl
  },
})

const to = computed(() => props.url?.path)

const style = computed(() => {
  const backgroundImage = buildWatchCollectionBackground(props.backgroundImage)
  if (!backgroundImage) {
    return
  }
  return {
    backgroundImage: `url(${backgroundImage})`,
  }
})

const countLabel = computed(() => {
  const total = props.watchVariationsCount
  if (!total) {
    return $texts('modelCountZero', 'No models')
  }

  return total === 1
    ? $texts('modelCountSingular', '1 model')
    : $texts('modelCountPlural', '@count models').replace(
        '@count',
        total.toString(),
      )
})
</script>

<style lang="scss" scoped>
.collection-teaser-root {
  container-type: inline-size;
}

.collection-teaser-div {
  padding-left: 12px;
  padding-right: 12px;
}

@container (min-width: 200px) {
  .collection-teaser-div {
    padding-left: 8%;
    padding-right: 8%;
  }
}
</style>
