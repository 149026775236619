<template>
  <nav class="md:order-last md:mt-48 lg:mt-0">
    <ul class="flex gap-24 mobile-only:flex-col xl:gap-48">
      <li v-for="(item, index) in links" :key="index">
        <DrupalLink
          :to="item?.link?.url?.path"
          class="inline-block text-base/[24px] font-light uppercase text-black hover:underline"
          :class="{
            'hover:decoration-pink': isRebranding,
          }"
        >
          {{ item.link.label }}
        </DrupalLink>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import type { MenuLinkTreeFirstFragment } from '#graphql-operations'

withDefaults(
  defineProps<{
    links?: MenuLinkTreeFirstFragment[] | null
  }>(),
  {
    links: () => [],
  },
)

const isRebranding = await useFeatureFlag()
</script>
